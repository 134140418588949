<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5 bg-auth"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="auth-bg"
      >
        <locale
          style="
            list-style: none;
            position: absolute;
            top: 20px;
            right: 32px;
            z-index: 1"
          @updateLocale="updateLocale"
        />
        <b-row class="d-flex align-items-center px-2 h-100">
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-5"
          >
            <div class="d-flex justify-content-center align-items-center">
              <vuexy-logo class="fs-2vw mr-1" />
              <h1
                class="my-5 text-center"
              >
                5 SKILLS
                <!-- "{{ $t('others.treaning_center') }}" -->
              </h1>
            </div>
            <div class="my-2" />
            <b-card-title
              title-tag="h2"
              class="card-title font-weight-bold my-1 pt-md-4"
            >
              {{ $t('others.authorization') }}
            </b-card-title>
            <!-- <b-card-text class="mb-2">
              Добро пожаловать, пожалуйста войдите в свой аккаунт
            </b-card-text> -->

            <!-- form -->
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
              >
                <!-- email -->
                <b-form-group
                  :label="$t('others.id_or_login')"
                  label-for="login-username"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('others.id_or_login')"
                    rules="required"
                  >
                    <b-form-input
                      id="login-username"
                      v-model="username"
                      :state="errors.length > 0 ? false : null"
                      name="login-username"
                      :placeholder="$t('others.user')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">{{ $t('users.password') }}</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('users.password')"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">
                      {{ errors[0] }}
                    </small>
                  </validation-provider>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  @click="validationForm"
                >
                  <!-- @click="login" -->
                  {{ $t('others.sign_in') }}
                </b-button>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  ValidationProvider,
  ValidationObserver,
  localize,
} from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  // BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  // BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import jwt_decode from 'jwt-decode'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'
import ru from '@/libs/i18n/vee-validate/ru.json'
import en from '@/libs/i18n/vee-validate/en.json'
import uz from '@/libs/i18n/vee-validate/uz.json'

export default {
  components: {
    BRow,
    BCol,
    // BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    Locale,
    // BCardText,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      username: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    localize(this.$i18n.locale, ru)
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          useJwt
            .login({ contact_id: this.username, password: this.password })
            .then(async resp => {
              useJwt.setToken(resp.data.access)
              useJwt.setRefreshToken(resp.data.refresh)
              store.dispatch('users/UPDATE_USER', { id: resp.data.user_data.id, language: this.$i18n.locale }).then(async user_resp => {
                const user_data = user_resp.data
                useJwt.setUserData(user_data)
                // const ability = [{ action: 'manage', subject: 'all' }]
                // const ability = user_data.permission.map(e => ({ action: 'manage', subject: e === 'admin' ? 'all' : e }))
                const ability = user_data.permission.map(e => ({ action: 'manage', subject: e }))
                useJwt.setUserAbilities(ability)
                this.$ability.update(ability)
                this.$router.push('/')
                if (user_data.language) this.$i18n.locale = user_data.language
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `${this.$t('others.login_succesfuly')}, ${user_data.contact.first_name}`,
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
              })
            })
            .catch(e => {
              if (e.response.data.error) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: e.response.data.error[this.$i18n.locale],
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('others.incorrect_login_or_password'),
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    updateLocale() {
      const { locale } = this.$i18n
      const langs = { uz, ru, en }
      localize(locale, langs[locale])
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  .bg-auth{
    background-color: #EDEEF1;
  }
</style>
